/**
 * Recommended content.
 */
import { apiGet } from '@/util/api';
import RecommendationEngine from '@/util/recommendationEngine';
import Icon from '@/icons/Icon.vue';
import ContentList from '@/components/ContentList.vue';
import Wheel from '@/components/Wheel/Wheel.vue';

export default {
  name: 'RecommendedComponent',
  components: {
    Icon,
    ContentList,
    Wheel,
  },
  props: {
    params: { type: Object, default: () => null },
    category: { type: String, default: '' },
    genre: { type: String, default: '' },
    country: { type: String, default: '' },
  },
  data: () => ({
    loading: true,
    firstLoad: false,
    contents: [],
    global: false,

    // Pagination configs
    currentPage: 1,
    perPage: 12,
    rows: 0,

    // Wheel
    seriesForWheel: null,
    surveys: [],
    signedUrlsForWheel: [],
    countriesForWheel: [],
    shouldGenerateHistograms: false,
    analysisFromWheel: null,
  }),
  async created() {
    if (
      (this.params && !this.params.labels) ||
      (!this.params.histogram && this.params.series)
    ) {
      this.seriesForWheel = this.params.series;
      await this.getSurveys();
      await this.getCountriesForWheel();
      this.shouldGenerateHistograms = true;
    } else if (!this.params || !this.params.labels || !this.params.histogram) {
      this.$router.push({ name: 'dashboard' });
    } else {
      await this.initRecommendation();
      this.loading = false;
    }
  },
  watch: {
    global: async function() {
      this.loading = true;
      await this.initRecommendation();
      this.loading = false;
    },
  },
  methods: {
    async initRecommendation(analysisPromise) {
      try {
        let analysis;
        if (analysisPromise) {
          analysis = await analysisPromise;
          this.shouldGenerateHistograms = false;
          analysis = {
            labels: analysis.fubi_labels,
            histogram: analysis.fubi_histogram,
            globalHistogram: analysis.fubi_histogram_global,
          };
          this.analysisFromWheel = analysis;
        } else if (this.analysisFromWheel) {
          analysis = this.analysisFromWheel;
        } else {
          analysis = this.params;
        }

        const engine = new RecommendationEngine(
          Object.fromEntries(
            analysis.labels.map((_, i) => [
              analysis.labels[i],
              this.global ? analysis.globalHistogram[i] : analysis.histogram[i],
            ]),
          ),
        );

        const tags = await apiGet(`/tag`);
        let contents = await apiGet(`/content?thumbnails=false&withTags=true`);

        this.firstLoad = true;

        contents = contents.filter(c => c.tags && c.tags.length > 0);
        engine.populateScores(contents);
        contents.sort((a, b) => b.score - a.score);
        contents = contents.filter(
          c => c.tags && c.tags.filter(t => t.category === 'GENRE').length > 1,
        );
        contents = contents.slice(0, 20);

        this.contents = [];

        const thumbnails = await apiGet(
          `/content/thumbnails?ids=${contents.map(c => c.id).join(',')}`,
        );

        const min = contents.reduce(function(prev, curr) {
          return prev.score < curr.score ? prev : curr;
        }).score;

        const max = contents.reduce(function(prev, curr) {
          return prev.score > curr.score ? prev : curr;
        }).score;

        contents.forEach(c => {
          if (c.created.indexOf('T') > -1) {
            const cdate = c.created
              .substr(0, c.created.indexOf('T'))
              .split('-');
            c.created = `${cdate[2]}/${cdate[1]}/${cdate[0]}`;
          }

          c.score = (5 * (c.score - min)) / (max - min);
          c.showBookmark = true;
          c.showTags = true;
          // c.showStarRating = true;
          c.thumbnail = thumbnails[c.id];
          c.tags = c.tags.filter(t => t.category === 'GENRE');
          c.tags.forEach(t => {
            if (t.category === 'GENRE') {
              const foundTag = tags.find(t2 => t.id == t2.id);
              if (foundTag.parentTag) {
                t.parentTag = tags.find(t2 => t2.id == foundTag.parentTag);
              }
            }
          });
        });
        this.contents = contents;
        this.loading = false;
      } catch {
        this.$bvToast.toast(this.$t('common_error_body'), {
          title: this.$t('common_error_title'),
          variant: 'danger',
        });
      }
    },

    // WHEEL
    async getSurveys() {
      try {
        this.surveys = await apiGet('/survey');
      } catch {
        this.$bvToast.toast(this.$t('dashboard_surveys_get_error'), {
          title: this.$t('common_error_title'),
          variant: 'danger',
        });
      }
    },

    async getSurvey(survey) {
      try {
        const i = this.surveys.findIndex(s => s.id === survey.id);
        this.surveys[i] = await apiGet(`/survey/${survey.id}`);
      } catch {
        this.$bvToast.toast(
          this.$t('dashboard_survey_get_error', [survey.name]),
          {
            title: this.$t('common_error_title'),
            variant: 'danger',
          },
        );
      }
    },

    async getCountriesForWheel() {
      try {
        const data = await apiGet(`/survey/${this.surveys[0].id}/countries`);
        this.countriesForWheel = data.countries;
        this.signedUrlsForWheel = data.signedUrls;
      } catch (e) {
        this.$bvToast.toast(this.$t('survey_error_get_countries'), {
          title: this.$t('common_error_title'),
          variant: 'danger',
        });
      }
    },
  },
};
