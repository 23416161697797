/**
 * User content card component for reuse
 */

import ContentCard from '@/components/ContentCard.vue';
export default {
  name: 'ContentList',
  components: { ContentCard },
  props: {
    contents: { type: Array, default: [] },
  },
};
