var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{staticClass:"view-inspiration print-hide",attrs:{"show":_vm.loading,"fixed":"","no-fade":!_vm.firstLoad,"opacity":"1"}},[_c('b-container',[_c('b-row',[_c('b-col',{staticClass:"py-3"},[_c('h3',[_vm._v(" "+_vm._s(_vm.$t('inspiration_page_recommendation_title'))+" ")])])],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","lg":"9"}},[_c('ContentList',{attrs:{"contents":_vm.contents}})],1),_c('b-col',{staticClass:"pl-1 pb-4",attrs:{"cols":"12","lg":"3"}},[_c('b-card',{attrs:{"body-class":"p-0"}},[_c('b-card-header',{staticClass:"p-2"},[_vm._v(_vm._s(_vm.$t('common_filters')))]),_c('b-card-body',{staticClass:"p-2"},[_c('b-container',[_c('b-form-row',[_c('b-col',[_c('b-check',{attrs:{"disabled":!(_vm.params && _vm.params.globalHistogram) &&
                        !(
                          _vm.analysisFromWheel &&
                          _vm.analysisFromWheel.globalHistogram
                        )},model:{value:(_vm.global),callback:function ($$v) {_vm.global=$$v},expression:"global"}},[_vm._v(_vm._s(_vm.$t('inspiration_page_recommendation_filter_global')))]),_c('div',{staticClass:"text-muted ml-4"},[(
                        (!_vm.params || !_vm.params.globalHistogram) &&
                          (!_vm.analysisFromWheel ||
                            !_vm.analysisFromWheel.globalHistogram)
                      )?_c('small',[_vm._v(_vm._s(_vm.$t( 'inspiration_page_recommendation_filter_global_disabled' )))]):_vm._e()])],1)],1)],1)],1)],1)],1)],1)],1),(_vm.shouldGenerateHistograms)?_c('Wheel',{ref:"wheel",staticStyle:{"position":"absolute","z-index":"-1","width":"1000px","height":"1000px"},attrs:{"surveys":_vm.surveys,"series":[],"getSurvey":_vm.getSurvey,"countries":_vm.countriesForWheel,"signedUrls":_vm.signedUrlsForWheel,"analyse":_vm.seriesForWheel},on:{"loaded":_vm.initRecommendation}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }