/**
 * User content card component for reuse
 */
import Icon from '@/icons/Icon.vue';
import { apiPost, apiDelete } from '@/util/api';

export default {
  name: 'ContentCard',
  components: {
    Icon,
  },
  props: {
    id: Number,
    name: String,
    thumbnail: String,
    url: String,
    description: String,
    created: String,
    curator: Object,
    author: String,
    tags: Array,
    score: Number,
    up: Number,
    down: Number,
    showBookmark: { type: Boolean, default: false },
    showStarRating: { type: Boolean, default: false },
    showTags: { type: Boolean, default: false },
  },
  data: () => ({
    bookmarkLoading: false,
  }),
  computed: {
    adjustedScore() {
      return this.score;
    },
    bookmarked() {
      return (
        this.$store.state.user.bookmarks &&
        this.$store.state.user.bookmarks.some(cId => cId === this.id)
      );
    },
  },
  methods: {
    async bookmarkContent() {
      this.bookmarkLoading = true;
      const removing = this.$store.state.user.bookmarks.some(
        bookmarkedId => bookmarkedId === this.id,
      );
      const contentName =
        this.name && this.name > 20
          ? this.name.substr(0, 20) + '...'
          : this.name || '';
      try {
        if (removing) {
          await apiDelete(`/content/bookmark/${this.id}`);
          this.$store.commit('removeBookmark', this.id);
        } else {
          await apiPost(`/content/bookmark/${this.id}`);
          this.$store.commit('addBookmark', this.id);
        }
        this.$bvToast.toast(
          this.$t(
            removing
              ? 'inspiration_page_success_unset_bookmark'
              : 'inspiration_page_success_set_bookmark',
            { contentName },
          ),
          {
            title: this.$t('common_success_title'),
            variant: 'success',
          },
        );
      } catch {
        this.$bvToast.toast(
          this.$t(
            removing
              ? 'inspiration_page_error_unset_bookmark'
              : 'inspiration_page_error_set_bookmark',
            { contentName },
          ),
          {
            title: this.$t('common_error_title'),
            variant: 'danger',
          },
        );
      } finally {
        this.bookmarkLoading = false;
      }
    },
  },
};
