export default class RecommendationEngine {
  histogram = [];
  options = {
    normalise: true,
    log: false,
  };
  constructor(histogram, options) {
    this.histogram = histogram;
    if (options && typeof options.normalise === 'boolean')
      this.options.normalise = options.normalise;
    if (options && typeof options.log === 'boolean')
      this.options.log = options.log;
  }

  populateScores(contents) {
    contents.forEach(content => {
      content.score = 0;
      let n = 0;
      content.tags.forEach(tag => {
        if (tag.category === 'GENRE') {
          if (this.histogram[tag.name] > 2) tag.sentiment = '++';
          else if (this.histogram[tag.name] > 1) tag.sentiment = '+';
          else if (this.histogram[tag.name] < 0.5) tag.sentiment = '-';
          else if (this.histogram[tag.name] < 1) tag.sentiment = '-';
          else tag.sentiment = '~';
          content.score += this.histogram[tag.name];
          n++;
        }
      });

      if (this.options.normalise && n > 0) {
        content.score /= n;
      }

      if (this.options.log && n !== 0) {
        content.score = log_n(content.score, 3);
      }
    });
    return contents;
  }
}

function log_n(x, n) {
  return Math.log(x) / Math.log(n);
}
