var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"body-class":"p-0"}},[_c('b-card-header',{staticClass:"p-2"},[_c('span',{staticClass:"float-right"},[(_vm.showStarRating)?_c('span',{staticClass:"mr-2"},[_c('b-form-rating',{staticClass:"p-0 m-0",staticStyle:{"background":"none","height":"1em"},attrs:{"readonly":"","inline":"","no-border":"","color":"goldenrod","precision":"2","size":"sm"},model:{value:(_vm.adjustedScore),callback:function ($$v) {_vm.adjustedScore=$$v},expression:"adjustedScore"}})],1):_vm._e(),(_vm.showBookmark)?_c('span',[(!_vm.bookmarkLoading && _vm.bookmarked)?_c('b-link',{on:{"click":_vm.bookmarkContent}},[_c('Icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticStyle:{"height":"1.5em","width":"1.5em","cursor":"pointer"},attrs:{"icon":"interface:bookmarkfill","stroke":"","fill":"","title":_vm.$t('inspiration_page_unbookmark_tooltip')}})],1):(!_vm.bookmarkLoading)?_c('b-link',{on:{"click":_vm.bookmarkContent}},[_c('Icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticStyle:{"height":"1.5em","width":"1.5em","cursor":"pointer"},attrs:{"icon":"interface:bookmarkoutline","stroke":"","fill":"","title":_vm.$t('inspiration_page_bookmark_tooltip')}})],1):_c('b-spinner',{staticStyle:{"height":"1.5em","width":"1.5em"}})],1):_vm._e()]),_c('b-link',{attrs:{"href":_vm.url,"target":"_blank"}},[_vm._v(_vm._s(_vm.name))])],1),_c('b-card-body',{staticClass:"p-2"},[_c('b-container',[_c('b-row',[_c('b-col',{attrs:{"cols":"3"}},[_c('b-link',{attrs:{"href":_vm.url,"target":"_blank"}},[_c('b-aspect',{staticClass:"rounded bg-secondary align-middle text-center",style:({
                backgroundImage: ("url(" + _vm.thumbnail + ")"),
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }),attrs:{"aspect":"4:3"}})],1)],1),_c('b-col',[_vm._v(_vm._s(_vm.description))])],1)],1)],1),_c('b-card-footer',{staticClass:"px-2 py-1"},[_c('b-row',[_c('b-col',{attrs:{"cols":"9"}},[(_vm.showTags)?_c('div',_vm._l((_vm.tags),function(tag){return _c('b-badge',{key:tag.id,class:[
              'mr-1',
              'my-1',
              tag.category === 'CATEGORY' &&
                ("bg-paddle-" + (tag.name.toLowerCase())),
              tag.category === 'GENRE' &&
                ("bg-paddle-" + (tag.parentTag &&
                  tag.parentTag.name.toLowerCase())) ],staticStyle:{"font-size":"100%"}},[_vm._v(_vm._s(tag.name)+" "),(tag.sentiment)?_c('Icon',{staticStyle:{"height":"1em","vertical-align":"text-top"},attrs:{"icon":("interface:sentiment" + (tag.sentiment))}}):_vm._e()],1)}),1):_vm._e()]),_c('b-col',{staticClass:"text-right",attrs:{"cols":"3"}},[_c('span',{staticClass:"pl-2 pr-1 d-inline-block"},[_c('b-icon',{attrs:{"icon":"calendar"}}),_vm._v(" "+_vm._s(_vm.created)+" ")],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }